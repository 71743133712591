import { Parallax } from "react-parallax";
import { motion } from "framer-motion";

import About from "../about/about.component";
import Projects from "../projects/projects.component";
import Contact from "../contact/contact.component";

import photo from "../../assets/main-photo.jpg";
import { ReactComponent as WhiteLogo } from "../../assets/black-logo.svg";

import "./landing.styles.scss";

const Landing = ({ iniRef, expRef, proRef, conRef }) => {
	return (
		<div className="landing-container" ref={iniRef}>
			<Parallax bgImage={photo} strength={800} contentClassName="image">
				<motion.div
					animate={{ scale: 1 }}
					initial={{ scale: 0 }}
					transition={{ duration: 2 }}
					className="landing-motion"
				>
					<div className="landing-content">
						<WhiteLogo className="landing-logo" />
						<p className="langing-txt">
							Electronic engineer & <br /> software developer
						</p>
					</div>
				</motion.div>
			</Parallax>

			<About expRef={expRef} />
			<Projects proRef={proRef} />
			<Contact conRef={conRef} />
		</div>
	);
};

export default Landing;
