import { useState, useEffect } from "react";

import WhiteLogo from "../../assets/black-logo.svg";

import "./navbar.styles.scss";

const Navbar = ({ hIniC, hExpC, hProC, hConC }) => {
	const [show, setShow] = useState(false);
	const [lastScrollY, setLastScrollY] = useState(0);

	const controlNavbar = () => {
		if (window.scrollY > lastScrollY) setShow(true);
		else setShow(true);
		setLastScrollY(window.scrollY);
	};

	useEffect(() => {
		window.addEventListener("scroll", controlNavbar);

		return () => {
			window.removeEventListener("scroll", controlNavbar);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastScrollY]);

	return (
		<div>
			{show ? (
				<div className="navbar-container">
					<div className="navbar-logo-container">
						<img
							className="navbar-logo"
							alt="logo"
							src={WhiteLogo}
							onClick={hIniC}
						/>
					</div>
					<div className="navbar-cat-container">
						<p className="navbar-cat" onClick={hExpC}>
							About me
						</p>
						<p className="navbar-cat" onClick={hProC}>
							Projects
						</p>
						<p className="navbar-cat" onClick={hConC}>
							Contact
						</p>
					</div>
				</div>
			) : (
				<div></div>
			)}
		</div>
	);
};

export default Navbar;
