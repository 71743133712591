import { Parallax } from "react-scroll-parallax";
import { motion } from "framer-motion";

import ReactLogo from "../../assets/react.png";
import HtmlLogo from "../../assets/html.png";
import CssLogo from "../../assets/css.png";
import JsLogo from "../../assets/js.png";
import PythonLogo from "../../assets/python.png";
import NodeLogo from "../../assets/node.png";
import CppLogo from "../../assets/c++.png";
import FlutterLogo from "../../assets/flutter.png";
import KicadLogo from "../../assets/kicad.png";
import ArduinoLogo from "../../assets/arduino.png";
import AtmelLogo from "../../assets/atmel.png";
import StLogo from "../../assets/st.png";
import NxpLogo from "../../assets/nxp.png";
import CLogo from "../../assets/c.png";

import "./about.styles.scss";

const About = ({ expRef }) => {
	return (
		<div className="about-container" ref={expRef}>
			<Parallax translateX={[-40, 10]}>
				<p className="title">ABOUT ME</p>
			</Parallax>
			<div className="logo-container">
				<motion.img
					src={KicadLogo}
					alt="logo"
					className="logo"
					animate={{ scale: [1, 0.5, 1] }}
					transition={{ duration: 5, repeat: Infinity }}
				/>
				<motion.img
					src={ArduinoLogo}
					alt="logo"
					className="logo"
					animate={{ scale: [0.5, 1, 0.5] }}
					transition={{ duration: 5, repeat: Infinity }}
				/>
				<motion.img
					src={AtmelLogo}
					alt="logo"
					className="logo"
					animate={{ scale: [1, 0.5, 1] }}
					transition={{ duration: 5, repeat: Infinity }}
				/>
				<motion.img
					src={StLogo}
					alt="logo"
					className="logo"
					animate={{ scale: [0.5, 1, 0.5] }}
					transition={{ duration: 5, repeat: Infinity }}
				/>
				<motion.img
					src={NxpLogo}
					alt="logo"
					className="logo"
					animate={{ scale: [1, 0.5, 1] }}
					transition={{ duration: 5, repeat: Infinity }}
				/>
				<motion.img
					src={CLogo}
					alt="logo"
					className="logo"
					animate={{ scale: [0.5, 1, 0.5] }}
					transition={{ duration: 5, repeat: Infinity }}
				/>
			</div>
			<p className="text">
				Hi! my name is David Palacios, I'm an 29 yrs electronic engineer
				from Colombia, I have experience in hardware development and
				production, also, as a software development in React Js, Node
				Js, Python, C/C++ and FLutter. I'm a technology driven person,
				moved by doing things better and always improving my self with
				every new and exiting project. You can count on my to make your
				technologic dreams become true.
			</p>

			<div className="logo-container">
				<motion.img
					src={ReactLogo}
					alt="logo"
					className="logo"
					animate={{ scale: [0.5, 1, 0.5] }}
					transition={{ duration: 5, repeat: Infinity }}
				/>
				<motion.img
					src={HtmlLogo}
					alt="logo"
					className="logo"
					animate={{ scale: [1, 0.5, 1] }}
					transition={{ duration: 5, repeat: Infinity }}
				/>
				<motion.img
					src={CssLogo}
					alt="logo"
					className="logo"
					animate={{ scale: [0.5, 1, 0.5] }}
					transition={{ duration: 5, repeat: Infinity }}
				/>
				<motion.img
					src={JsLogo}
					alt="logo"
					className="logo"
					animate={{ scale: [1, 0.5, 1] }}
					transition={{ duration: 5, repeat: Infinity }}
				/>
				<motion.img
					src={NodeLogo}
					alt="logo"
					className="logo"
					animate={{ scale: [0.5, 1, 0.5] }}
					transition={{ duration: 5, repeat: Infinity }}
				/>
				<motion.img
					src={PythonLogo}
					alt="logo"
					className="logo"
					animate={{ scale: [1, 0.5, 1] }}
					transition={{ duration: 5, repeat: Infinity }}
				/>
				<motion.img
					src={CppLogo}
					alt="logo"
					className="logo"
					animate={{ scale: [0.5, 1, 0.5] }}
					transition={{ duration: 5, repeat: Infinity }}
				/>
				<motion.img
					src={FlutterLogo}
					alt="logo"
					className="logo"
					animate={{ scale: [1, 0.5, 1] }}
					transition={{ duration: 5, repeat: Infinity }}
				/>
			</div>
		</div>
	);
};

export default About;
