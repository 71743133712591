import { useState } from "react";
import { Link } from "react-router-dom";
import { Parallax } from "react-scroll-parallax";
import { toast } from "react-toastify";

import { ReactComponent as UserLogo } from "../../assets/name.svg";
import { ReactComponent as EmailLogo } from "../../assets/email.svg";
import { ReactComponent as NumberLogo } from "../../assets/phone.svg";

import "./contact.styles.scss";

const Contact = ({ conRef }) => {
	const [name, setName] = useState("");
	const [userEmail, setUserEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [description, setDescription] = useState("");

	const email = "bercelius-44@hotmail.com";
	const subject = "New exiting project";
	const body = `Hi David,%0D%0A%0D%0AMy name is ${name}, I'm reaching you because I have a greate project in mind.%0D%0A%0D%0AHere is the description: ${description}%0D%0A%0D%0A%0D%0A%0D%0AYou can reachme by my email: ${email} or my phone ${phone}.%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0ARegards,%0D%0A%0D%0A${name}`;

	const href = `mailto:${email}?subject=${subject}&body=${body}`;

	const error = (msg) => toast.error(msg);

	const handleSubmit = (e) => {
		if (name === "") {
			error("Name is needed");
			return;
		} else if (userEmail === "") {
			error("Email is needed");
			return;
		} else if (
			!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(userEmail)
		) {
			error("Wrong email format");
			return;
		} else if (phone === "") {
			error("Phone is needed");
			return;
		} else if (
			!/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(
				phone
			)
		) {
			error("Wrong phone number");
			return;
		} else if (description === "") {
			error("Description is needed");
			return;
		}

		window.location.href = href;
		e.preventDefault();
		return;
	};

	return (
		<div className="con-container" ref={conRef}>
			<Parallax translateX={[-37, 10]}>
				<p className="title">CONTACT</p>
			</Parallax>
			<p className="text text1">
				So, if you are exited like me to build amazing projects and work
				a lot, feel free to get in touch 😎🤗😁.
			</p>
			<div className="contact-form">
				<div className="input-container">
					<UserLogo className="logo" />
					<input
						type="text"
						placeholder="name"
						onFocus={(e) => (e.target.placeholder = "")}
						onBlur={(e) => (e.target.placeholder = "name")}
						onChange={(e) => setName(e.target.value)}
					/>
				</div>
				<div className="input-container">
					<EmailLogo className="logo" />
					<input
						type="text"
						placeholder="email"
						onFocus={(e) => (e.target.placeholder = "")}
						onBlur={(e) => (e.target.placeholder = "email")}
						onChange={(e) => setUserEmail(e.target.value)}
					/>
				</div>
				<div className="input-container">
					<NumberLogo className="logo" />
					<input
						type="text"
						placeholder="contact number / whatsapp"
						onFocus={(e) => (e.target.placeholder = "")}
						onBlur={(e) =>
							(e.target.placeholder = "contact number / whatsapp")
						}
						onChange={(e) => setPhone(e.target.value)}
					/>
				</div>
				<div className="input-container">
					<textarea
						placeholder="description"
						onFocus={(e) => (e.target.placeholder = "")}
						onBlur={(e) => (e.target.placeholder = "description")}
						onChange={(e) => setDescription(e.target.value)}
					/>
				</div>
				<Link
					className="contact-btn"
					to="#"
					href={href}
					onClick={handleSubmit}
				>
					Contact
				</Link>
			</div>
		</div>
	);
};

export default Contact;
