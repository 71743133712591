import MainRouter from "./router/router";
import { ToastContainer } from "react-toastify";
import { ParallaxProvider } from "react-scroll-parallax";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
	return (
		<div className="App">
			<ParallaxProvider>
				<MainRouter />
			</ParallaxProvider>
			<ToastContainer theme="light" />
		</div>
	);
}

export default App;
