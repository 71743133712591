import { useRef } from "react";

import Landing from "../components/landing/landing.component";
import Navbar from "../components/navbar/navbar.component";

const MainRouter = () => {
	const iniRef = useRef(null);
	const expRef = useRef(null);
	const proRef = useRef(null);
	const conRef = useRef(null);

	const hIniC = () => iniRef.current?.scrollIntoView({ behavior: "smooth" });
	const hExpC = () => expRef.current?.scrollIntoView({ behavior: "smooth" });
	const hProC = () => proRef.current?.scrollIntoView({ behavior: "smooth" });
	const hConC = () => conRef.current?.scrollIntoView({ behavior: "smooth" });

	return (
		<div>
			<Navbar hIniC={hIniC} hExpC={hExpC} hProC={hProC} hConC={hConC} />
			<Landing
				iniRef={iniRef}
				expRef={expRef}
				proRef={proRef}
				conRef={conRef}
			/>
			<footer>All rights reserved bercke.com</footer>
		</div>
	);
};

export default MainRouter;
