import { Parallax } from "react-scroll-parallax";

import PcbPhoto from "../../assets/pcb.PNG";
import AnubisPhoto from "../../assets/anubis.PNG";
import SiempreFacilPhoto from "../../assets/siemprefacil.PNG";

import "./projects.styles.scss";

const Projects = ({ proRef }) => {
	return (
		<div className="pro-container" ref={proRef}>
			<Parallax translateX={[45, -10]} className="parallax">
				<p className="title">PROJECTS</p>
			</Parallax>
			<p className="text text2">
				As a full-stack developer I have been involved in plenty of web,
				mobile, and server-focused projects. In backend development I
				have worked with computer vision, facial recognition, OCR, web
				scraping, REST services and payment gateway integrations.
				Technologies incorporated into these projects include Python,
				Node, OpenCV, TesseractOCR, Selenium, Express, and Ajax.
				Additionally, I am familiar with relational and non-relational
				databases such as PostgreSQL and MongoDB.
				<br /> As a frontend developer I have experience using HTML,
				CSS, JS, React, and related packages such as ReactRouter,
				ReactParallax, ReactScrollParallax, Sass and FramerMotion.
				Currently, I am learning Flutter to expand my knowledge in
				mobile development.
			</p>
			<div className="photos-container">
				<img src={AnubisPhoto} alt="anubis" className="pcb-photo" />
				<img
					src={SiempreFacilPhoto}
					alt="siempreFacil"
					className="pcb-photo"
				/>
			</div>
			<p className="text text3">
				Within the scope of hardware engineering, I have experience
				designing, developing, and producing electronic devices using
				CAD programs such as KiCad and Fusion360. My dream has always
				been to work with IoT projects, but I'm open to any kind of
				project because you always can learn something new 😁😁😁.
			</p>
			<div className="photos-container">
				<img src={PcbPhoto} alt="main pcb" className="pcb-photo" />
			</div>
		</div>
	);
};

export default Projects;
